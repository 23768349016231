<template>
  <div
    class="PersonalView">
    <HeaderStep
      :logo="flow?.headerLogo"
      :theme="currentStep.headerTheme"
      :title="$t('flows.shared.steps.personal.title')"
      :subtitle="$t('flows.shared.steps.personal.subtitle')"
      :progress-label="$t('flows.shared.steps.personal.progressLabel')"
      :stepper-steps="stepperSteps"
      :active-stepper-step-id="currentStep.id"
    />

    <div class="container">
      <AccordionForm
        name="personal"
        :initial-open="isCurrentStepValidClientSide ? null : 0"
        :drawers="drawers"
        :has-submitted="hasSubmitted">
      </AccordionForm>

      <ErrorTile
        class="mt-2"
        v-show="hasSubmitted && !isCurrentStepValidClientSide">
        {{ $t('shared.pleaseCompleteFields') }}
      </ErrorTile>

      <div
        class="text-center mt-4 pb-6">
        <ButtonBase
          class="uppercase"
          type="submit"
          :is-loading="isSubmitting"
          @click="handleSubmit">
          {{ computedCtaText }}
        </ButtonBase>
      </div>
    </div>
  </div>
</template>


<script>
import HeaderStep from '@/components/HeaderStep.vue';
import AccordionForm from '@/components/AccordionForm.vue';
import ErrorTile from '@/components/ErrorTile.vue';
import ButtonBase from '@/components/ButtonBase.vue';

export default {
  name: 'PersonalView',

  components: {
    HeaderStep,
    AccordionForm,
    ErrorTile,
    ButtonBase,
  },

  inject: [
    'domo',
    'flow',
    'session',
    'nextStep',
    'currentStep',
    'stepperSteps',
    'isCurrentStepValidClientSide',
  ],

  data() {
    return {
      isSubmitting: false,
      hasSubmitted: false,
      drawers: [
        {
          label: this.$t('shared.whatTeamDoYouWorkOn'),
          errorMessage: this.$t('shared.teamIsRequired'),
          inputType: 'select',
          model: 'department',
          inputSelectTheme: 'default',
          props: {
            isTiles: true,
            isMultiSelect: false,
            options: this.$i18n.messages[this.$i18n.locale].departments,
          },
          isOtherable: true,
          modelOther: 'departmentOther',
          propsOther: {
            labelQuestion: this.$t('shared.notSeeingYourTeam'),
            labelCta: this.$t('shared.whatTeamAreYouOn'),
            labelInstruction: this.$t('shared.whatTeamAreYouOn'),
            placeholder: this.$t('shared.departmentOrTeam'),
          },
        },
        {
          label: this.$t('shared.whatBestDescribesYourRole'),
          errorMessage: this.$t('shared.roleIsRequired'),
          inputType: 'select',
          model: 'role',
          inputSelectTheme: 'icon',
          props: {
            isTiles: true,
            isMultiSelect: false,
            options: this.$i18n.messages[this.$i18n.locale].roles,
          },
          isOtherable: true,
          modelOther: 'roleOther',
          propsOther: {
            labelQuestion: this.$t('shared.notSeeingYourRole'),
            labelCta: this.$t('shared.tellUsWhatYouDo'),
            labelInstruction: this.$t('shared.tellUsWhatYouDo'),
            placeholder: this.$t('shared.role'),
          },
        },
        {
          label: this.$t('shared.whatIsYourJobTitle'),
          errorMessage: this.$t('shared.jobTitleIsRequired'),
          inputType: 'text',
          model: 'jobTitle',
          onEnter: this.handleSubmit,
          props: {
            placeholder: this.$t('shared.jobTitle'),
            isPlaceholderPersistent: true,
            isInvalid: false,
          },
        },
      ],
    };
  },

  computed: {
    computedCtaText() {
      return !this.nextStep
        ? this.$t('ctas.finishAndLaunchDomo')
        : this.$t('ctas.next');
    },
  },

  methods: {
    async handleSubmit() {
      this.hasSubmitted = true;

      if (this.isSubmitting || !this.isCurrentStepValidClientSide) return;

      this.isSubmitting = true;

      await this.currentStep.submitHandler({
        domo: this.domo,
        session: this.session,
        currentStep: this.currentStep,
      });

      if (!this.nextStep) return;

      this.$router.push({
        name: 'step',
        params: {
          lang: this.$route.params.lang || '',
          flowName: this.$route.params.flowName,
          stepName: this.nextStep.routeName,
        },
        query: this.$route.query,
      });
    },
  },
};
</script>
